export default [
    {
        value: '',
        label: 'Display All'
    },
    {
        value: true,
        label: 'Locked'
    },
    {
        value: false,
        label: 'Unlocked'
    }
];
