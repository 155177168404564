// Use to select # of pay periods to display
function generatePayPeriods(maxPeriods) {
    if (maxPeriods < 1) return null;

    let periods = [];
    let count = 1;

    while (count <= maxPeriods) {
        periods.push({
            value: `${count}`,
            label: count === 1 ? 'Current Pay Period' : `${count} Pay Periods`
        });
        count++;
    }

    return periods;
}

export default [...generatePayPeriods(24)];
