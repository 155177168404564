export default [
    {
        href: '/commissions/',
        label: 'Salespeople',
        exact: true
    },
    {
        href: '/commissions/branch-summary/',
        label: 'Branches',
        exact: true
    }
];
