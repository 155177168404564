import React from 'react';
import classes from './navTabs.css';
import { array } from 'prop-types';
import Link from 'src/components/Link';

function NavTabs(props) {
    const { items } = props;

    return (
        <div className={classes.root}>
            {items.map(item => {
                return (
                    <Link
                        key={item.label}
                        href={item.href}
                        activeClassName={classes.linkActive}
                        className={classes.link}
                        exact={item.exact}
                    >
                        {item.label}
                    </Link>
                );
            })}
        </div>
    );
}

NavTabs.propTypes = {
    items: array
};

export default NavTabs;
