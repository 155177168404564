import React from 'react';
import PageHeader from 'src/components/PageHeader';
import TableWrapper from 'src/components/TableWrapper';
import { TableController } from 'fronds/Components';
import {
    any,
    arrayOf,
    shape,
    string,
    number,
    bool,
    instanceOf
} from 'prop-types';
import QueryParams from 'src/util/urlParams/queryParams';
import classes from './commissions.css';
import NavTabs from 'src/components/NavTabs';
import commissionNavItems from './commissionNavItems';
import payPeriods from 'src/constants/payPeriods';
import { useSelector } from 'react-redux';
import Splash from 'src/components/Splash';
import NoRules from 'src/icons/norules.svg';
import userRoles from 'src/constants/userRoles';
import filterStatuses from './commissionLockedStatuses';
import { resultsPerPageDropdownOptions } from 'src/constants/resultsPerPageOptions';
import DropdownSelectWrapper from 'src/components/DropdownSelectWrapper';
import PaginationWrapper from 'src/components/PaginationWrapper';
const isEqual = require('lodash/isEqual');

function Commissions(props) {
    const { tableRows, headers, queryParams } = props;
    const { roles } = useSelector(state => state.user);

    const visibleHeaders = headers.filter(header => {
        // Make sure headers exist as a string
        if (
            queryParams.query &&
            typeof queryParams.query.headers === 'string'
        ) {
            const queryHeaders = queryParams.query.headers.split(',');
            if (queryHeaders.includes(header.value) || header.static) {
                return header;
            }
        }
    });

    function handleSort(sort, asc) {
        queryParams
            .setSortParams(sort, asc)
            .setPaginationParams(1)
            .setURLParams();
    }

    function handleColumnSearch(search, header) {
        queryParams
            .setColumnSearchParam(header, search, visibleHeaders)
            .setColumnComparatorParams(header, visibleHeaders)
            .setPaginationParams(1)
            .setURLParams();
    }

    const onSelectionChange = opt => {
        queryParams
            .setPayPeriods(opt.value)
            .setPaginationParams(1)
            .setURLParams();
    };

    function changeFilterOption(option) {
        queryParams
            .setFilterParams(option.value)
            .setPaginationParams(1)
            .setURLParams();
    }

    function handleResultsPerPageChange(resultsPerPage) {
        queryParams
            .setResultsPerPageParam(resultsPerPage.value)
            .setPaginationParams(1)
            .setURLParams();
    }

    const headerProps = {
        handleSort,
        initialSort: queryParams.sort,
        handleColumnSearch,
        initialColumnSearch: queryParams.getColumnSearch(visibleHeaders)
    };

    const onHeaderChange = selectedItems => {
        if (selectedItems.length === 0) return;
        queryParams.setHeaders(selectedItems).setURLParams();
    };

    const commissionsTableFallback = (
        <Splash
            isCompact={false}
            image={<NoRules />}
            message={'No commissions to show'}
        />
    );

    return (
        <>
            <PageHeader text={'Commissions'} />
            {roles.includes(userRoles.admin) ||
            roles.includes(userRoles.gm) ||
            roles.includes(userRoles.payroll) ||
            roles.includes(userRoles.orderSupportManager) ? (
                <NavTabs items={commissionNavItems} />
            ) : null}
            <TableController
                inheritedClass={classes.tableController}
                left={
                    <>
                        <DropdownSelectWrapper
                            placeholder={'# of Pay Periods...'}
                            listItems={payPeriods}
                            label={'Display Pay Periods'}
                            onSelectionChange={onSelectionChange}
                            selectedValues={
                                payPeriods.find(
                                    payPeriod =>
                                        queryParams.payPeriods ===
                                        payPeriod.value
                                ) || payPeriods[0]
                            }
                        />
                        <DropdownSelectWrapper
                            onSelectionChange={opt => changeFilterOption(opt)}
                            listItems={filterStatuses}
                            label={'Filter by Lock Status'}
                            isClearable={false}
                            selectedValues={
                                queryParams.filter
                                    ? filterStatuses.find(
                                          filter =>
                                              filter.value.toString() ===
                                              queryParams.filter.toString()
                                      )
                                    : filterStatuses[0]
                            }
                        />
                        <DropdownSelectWrapper
                            selectedValues={headers.filter(baseHeader => {
                                return visibleHeaders.find(header => {
                                    return isEqual(baseHeader, header);
                                });
                            })}
                            listItems={headers.filter(header => {
                                return !header.static;
                            })}
                            placeholder={'Set headers'}
                            onSelectionChange={onHeaderChange}
                            isMulti={true}
                            isClearable={false}
                            hideSelectedOptions={false}
                            controlShouldRenderValue={false}
                            isOptionDisabled={option =>
                                queryParams.headers.length <= 4 &&
                                queryParams.headers.find(header =>
                                    isEqual(header, option.value)
                                )
                            }
                        />
                    </>
                }
                right={
                    <>
                        <DropdownSelectWrapper
                            label={'Page Size'}
                            inheritedClass={'mini'}
                            listItems={resultsPerPageDropdownOptions}
                            selectedValues={
                                resultsPerPageDropdownOptions.find(
                                    opt =>
                                        opt.value === queryParams.resultsPerPage
                                ) || resultsPerPageDropdownOptions[0]
                            }
                            onSelectionChange={handleResultsPerPageChange}
                        />
                        <PaginationWrapper
                            totalRows={
                                tableRows.filteredRows
                                    ? tableRows.filteredRows.length
                                    : 0
                            }
                            handlePagination={page => {
                                queryParams
                                    .setPaginationParams(page)
                                    .setURLParams();
                            }}
                            currentPage={queryParams.pageNumber}
                            pageSize={queryParams.resultsPerPage}
                        />
                    </>
                }
            />

            <TableWrapper
                rows={tableRows.getPage(queryParams.pageNumber)}
                headers={visibleHeaders}
                headerProps={headerProps}
                fallback={commissionsTableFallback}
                primaryKey={'payPeriodId'}
            />
        </>
    );
}

Commissions.propTypes = {
    tableRows: any,
    queryParams: instanceOf(QueryParams),
    rows: arrayOf(
        shape({
            beneficiaryUserId: string.isRequired,
            branch: string.isRequired,
            payPeriodId: number.isRequired,
            payPeriodDescription: string.isRequired,
            isLocked: bool.isRequired,
            totalPayout: number.isRequired
        })
    ),
    headers: any
};

export default Commissions;
